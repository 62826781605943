// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-content2{
    width: 80vh !important;
    overflow: auto;
    overflow-x:hidden;
}`, "",{"version":3,"sources":["webpack://./src/layoutV2/style.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,cAAc;IACd,iBAAiB;AACrB","sourcesContent":[".main-content2{\n    width: 80vh !important;\n    overflow: auto;\n    overflow-x:hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
