// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Software.css */

.software-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

h1 {
    text-align: center;
    margin-bottom: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th, td {
    padding: 12px 15px;
    border: 1px solid #ddd;
}

th {
    background-color: #007bff;
    color: black;
    text-align: left;
}

td input {
    width: calc(100% - 10px);
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.chip {
    display: inline-block;
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: bold;
}

.active {
    background-color: #4caf50; /* Green */
    color: #fff;
}

.error {
    background-color: #f44336; /* Red */
    color: #fff;
}

.inactive {
    background-color: #ffa500; /* Orange */
    color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Settings/Software/index.css"],"names":[],"mappings":"AAAA,iBAAiB;;AAEjB;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,wBAAwB;IACxB,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;IACjB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,yBAAyB,EAAE,UAAU;IACrC,WAAW;AACf;;AAEA;IACI,yBAAyB,EAAE,QAAQ;IACnC,WAAW;AACf;;AAEA;IACI,yBAAyB,EAAE,WAAW;IACtC,WAAW;AACf","sourcesContent":["/* Software.css */\n\n.software-container {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    background-color: #f9f9f9;\n}\n\nh1 {\n    text-align: center;\n    margin-bottom: 20px;\n}\n\ntable {\n    width: 100%;\n    border-collapse: collapse;\n    margin-top: 20px;\n}\n\nth, td {\n    padding: 12px 15px;\n    border: 1px solid #ddd;\n}\n\nth {\n    background-color: #007bff;\n    color: black;\n    text-align: left;\n}\n\ntd input {\n    width: calc(100% - 10px);\n    padding: 8px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n}\n\n.chip {\n    display: inline-block;\n    padding: 6px 12px;\n    border-radius: 20px;\n    font-size: 14px;\n    font-weight: bold;\n}\n\n.active {\n    background-color: #4caf50; /* Green */\n    color: #fff;\n}\n\n.error {\n    background-color: #f44336; /* Red */\n    color: #fff;\n}\n\n.inactive {\n    background-color: #ffa500; /* Orange */\n    color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
