// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
    text-align: center;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
  }
  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }
  .table td,
  .table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }
  ::-webkit-input-placeholder {
    font-weight: 400;
  }
  :-moz-placeholder {
    font-weight: 400;
  }
  ::-moz-placeholder {
    font-weight: 400;
  }
  :-ms-input-placeholder {
    font-weight: 400;
  }`, "",{"version":3,"sources":["webpack://./src/pages/TimeSheet/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;IACX,mBAAmB;IACnB,cAAc;EAChB;EACA;IACE,kBAAkB;IAClB,uBAAuB;IACvB,qBAAqB;EACvB;EACA;IACE,sBAAsB;IACtB,gCAAgC;EAClC;EACA;;IAEE,gBAAgB;IAChB,mBAAmB;IACnB,6BAA6B;EAC/B;EACA;IACE,gBAAgB;EAClB;EACA;IACE,gBAAgB;EAClB;EACA;IACE,gBAAgB;EAClB;EACA;IACE,gBAAgB;EAClB","sourcesContent":["table {\n    text-align: center;\n    border-collapse: collapse;\n    width: 100%;\n    margin-bottom: 1rem;\n    color: #212529;\n  }\n  tr {\n    display: table-row;\n    vertical-align: inherit;\n    border-color: inherit;\n  }\n  .table thead th {\n    vertical-align: bottom;\n    border-bottom: 2px solid #dee2e6;\n  }\n  .table td,\n  .table th {\n    padding: 0.75rem;\n    vertical-align: top;\n    border-top: 1px solid #dee2e6;\n  }\n  ::-webkit-input-placeholder {\n    font-weight: 400;\n  }\n  :-moz-placeholder {\n    font-weight: 400;\n  }\n  ::-moz-placeholder {\n    font-weight: 400;\n  }\n  :-ms-input-placeholder {\n    font-weight: 400;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
