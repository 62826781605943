// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  /* margin-right: 15rem !important; */
  max-width: 800px !important;
  margin: 0 auto;
  padding: 20px;
  padding-top: 10px !important;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
}

input[type="text"],
input[type="password"],
textarea {
  width: 100%;
  padding: 10px;
  padding-top: 8px !important;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  resize: vertical; /* Allow vertical resizing of the textarea */
}

input[type="checkbox"] {
  /* width: 100%; */
  width: 15px;
  margin-left: 4px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  resize: vertical; /* Allow vertical resizing of the textarea */
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

input[type="checkbox"] {
  margin-right: 10px; /* Add margin to create space between checkbox and label */
}
`, "",{"version":3,"sources":["webpack://./src/pages/Settings/Accounts/index.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,2BAA2B;EAC3B,cAAc;EACd,aAAa;EACb,4BAA4B;EAC5B,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;;;EAGE,WAAW;EACX,aAAa;EACb,2BAA2B;EAC3B,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;EACtB,gBAAgB,EAAE,4CAA4C;AAChE;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;EACtB,eAAe;EACf,gBAAgB,EAAE,4CAA4C;AAChE;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB,EAAE,0DAA0D;AAChF","sourcesContent":[".container {\n  /* margin-right: 15rem !important; */\n  max-width: 800px !important;\n  margin: 0 auto;\n  padding: 20px;\n  padding-top: 10px !important;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  background-color: #f9f9f9;\n}\n\n.form-group {\n  margin-bottom: 20px;\n}\n\nlabel {\n  display: block;\n  font-weight: bold;\n}\n\ninput[type=\"text\"],\ninput[type=\"password\"],\ntextarea {\n  width: 100%;\n  padding: 10px;\n  padding-top: 8px !important;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  box-sizing: border-box;\n  resize: vertical; /* Allow vertical resizing of the textarea */\n}\n\ninput[type=\"checkbox\"] {\n  /* width: 100%; */\n  width: 15px;\n  margin-left: 4px;\n  padding: 10px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  box-sizing: border-box;\n  cursor: pointer;\n  resize: vertical; /* Allow vertical resizing of the textarea */\n}\n\nbutton {\n  padding: 10px 20px;\n  border: none;\n  border-radius: 5px;\n  background-color: #007bff;\n  color: #fff;\n  cursor: pointer;\n}\n\nbutton:hover {\n  background-color: #0056b3;\n}\n\ninput[type=\"checkbox\"] {\n  margin-right: 10px; /* Add margin to create space between checkbox and label */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
