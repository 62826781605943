// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title{
    font-family: Poppins;
    font-style: normal;
    font-size: 23px;
    font-weight: 500;
    line-height: 34px;
}
.titleHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    padding:10px;
    flex-wrap: wrap;
}
.export-div{
  width: 113px;
  height: 37px;
  background-color: #EDF8F7;
  border-radius: 40px;
}
.export-in{
  position: relative;
  left: 15px;
  top:5px;
}
.export-drop{
  margin-right: 100px;;
}
.export-custom{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.customize{
  margin-top: 5px;
  margin-left: 10px;
  color: #2A8981;
}
.cust{
  display: flex;
  margin-left: 20px;
}
.custom-text{
  font-weight: 500;
  font-size: 14px!important;
}
.header-icon{
  width:44px;
  height:44px;
}
.greenColor{
  color:#2A8981;
}
.wh45{
  width:45px;
  height:45px;
}
.wh25{
  width:25px;
  height:25px;
}`, "",{"version":3,"sources":["webpack://./src/components/TitleHeader/styles.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,2CAA2C;IAC3C,YAAY;IACZ,eAAe;AACnB;AACA;EACE,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,OAAO;AACT;AACA;EACE,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,aAAa;EACb,iBAAiB;AACnB;AACA;EACE,gBAAgB;EAChB,yBAAyB;AAC3B;AACA;EACE,UAAU;EACV,WAAW;AACb;AACA;EACE,aAAa;AACf;AACA;EACE,UAAU;EACV,WAAW;AACb;AACA;EACE,UAAU;EACV,WAAW;AACb","sourcesContent":[".title{\n    font-family: Poppins;\n    font-style: normal;\n    font-size: 23px;\n    font-weight: 500;\n    line-height: 34px;\n}\n.titleHeader{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);\n    padding:10px;\n    flex-wrap: wrap;\n}\n.export-div{\n  width: 113px;\n  height: 37px;\n  background-color: #EDF8F7;\n  border-radius: 40px;\n}\n.export-in{\n  position: relative;\n  left: 15px;\n  top:5px;\n}\n.export-drop{\n  margin-right: 100px;;\n}\n.export-custom{\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.customize{\n  margin-top: 5px;\n  margin-left: 10px;\n  color: #2A8981;\n}\n.cust{\n  display: flex;\n  margin-left: 20px;\n}\n.custom-text{\n  font-weight: 500;\n  font-size: 14px!important;\n}\n.header-icon{\n  width:44px;\n  height:44px;\n}\n.greenColor{\n  color:#2A8981;\n}\n.wh45{\n  width:45px;\n  height:45px;\n}\n.wh25{\n  width:25px;\n  height:25px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
