// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Assets.css */
.assets-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

h1 {
    text-align: center;
}

.buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.buttons button {
    padding: 10px 20px;
    margin: 0 5px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
}

.buttons button:hover {
    background-color: #0056b3;
}
.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Vertically center align items */
    margin-bottom: 20px;
}

.asset {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
}

.asset h2 {
    margin-bottom: 10px;
}

.details {
    margin-top: 10px;
}

.details p {
    margin: 5px 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Settings/Hardware/index.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB,EAAE,kCAAkC;IACvD,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB","sourcesContent":["/* Assets.css */\n.assets-container {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    background-color: #f9f9f9;\n}\n\nh1 {\n    text-align: center;\n}\n\n.buttons {\n    display: flex;\n    justify-content: center;\n    margin-bottom: 20px;\n}\n\n.buttons button {\n    padding: 10px 20px;\n    margin: 0 5px;\n    border: none;\n    border-radius: 5px;\n    background-color: #007bff;\n    color: #fff;\n    cursor: pointer;\n}\n\n.buttons button:hover {\n    background-color: #0056b3;\n}\n.headerContainer {\n    display: flex;\n    justify-content: space-between;\n    align-items: center; /* Vertically center align items */\n    margin-bottom: 20px;\n}\n\n.asset {\n    margin-bottom: 20px;\n    padding: 15px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    background-color: #fff;\n}\n\n.asset h2 {\n    margin-bottom: 10px;\n}\n\n.details {\n    margin-top: 10px;\n}\n\n.details p {\n    margin: 5px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
