// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/svg/search-icon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-header {
  font-size: 20px;
  font-family: 'Poppins';
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  margin-top: 6px;
}

.search {
  position: absolute;
  left: 500px;
  top: 11.8%
}

.search-input {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: 10px center;
  background-repeat: no-repeat;
  padding-left: 40px;
  /* Adjust the padding as needed */
}

.graphCardHeight {
  /* height:550px !important */
}

.bubble{
  width:'200px'
}
/* .availability{
  margin-top: -80px !important;
} */`, "",{"version":3,"sources":["webpack://./src/pages/ResourceManagement/styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,sBAAsB;EACtB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX;AACF;;AAEA;EACE,yDAAyD;EACzD,gCAAgC;EAChC,4BAA4B;EAC5B,kBAAkB;EAClB,iCAAiC;AACnC;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE;AACF;AACA;;GAEG","sourcesContent":[".top-header {\n  font-size: 20px;\n  font-family: 'Poppins';\n  font-weight: 500;\n  line-height: 28px;\n  text-align: center;\n  margin-top: 6px;\n}\n\n.search {\n  position: absolute;\n  left: 500px;\n  top: 11.8%\n}\n\n.search-input {\n  background-image: url('../../assets/svg/search-icon.svg');\n  background-position: 10px center;\n  background-repeat: no-repeat;\n  padding-left: 40px;\n  /* Adjust the padding as needed */\n}\n\n.graphCardHeight {\n  /* height:550px !important */\n}\n\n.bubble{\n  width:'200px'\n}\n/* .availability{\n  margin-top: -80px !important;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
