// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-source-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.upload-section {
    margin-bottom: 30px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.upload-section h2 {
    margin-bottom: 15px;
    font-size: 22px;
    color: #333;
    font-weight: 600;
}

.upload-section input[type="file"] {
    display: block;
    margin-bottom: 15px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    font-size: 16px;
}

.upload-section button {
    padding: 12px 24px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.upload-section button:hover {
    background-color: #0056b3;
}

.upload-section button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.upload-section button:disabled:hover {
    background-color: #cccccc;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Settings/dataSource/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,cAAc;IACd,yBAAyB;IACzB,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,8CAA8C;IAC9C,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,kDAAkD;IAClD,wCAAwC;AAC5C;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".data-source-container {\n    padding: 20px;\n    max-width: 800px;\n    margin: 0 auto;\n    background-color: #f8f9fa;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.upload-section {\n    margin-bottom: 30px;\n    padding: 20px;\n    background-color: #fff;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.upload-section h2 {\n    margin-bottom: 15px;\n    font-size: 22px;\n    color: #333;\n    font-weight: 600;\n}\n\n.upload-section input[type=\"file\"] {\n    display: block;\n    margin-bottom: 15px;\n    padding: 8px;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);\n    font-size: 16px;\n}\n\n.upload-section button {\n    padding: 12px 24px;\n    font-size: 16px;\n    color: #fff;\n    background-color: #007bff;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    transition: background-color 0.3s, box-shadow 0.3s;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.upload-section button:hover {\n    background-color: #0056b3;\n}\n\n.upload-section button:disabled {\n    background-color: #cccccc;\n    cursor: not-allowed;\n}\n\n.upload-section button:disabled:hover {\n    background-color: #cccccc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
